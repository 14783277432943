import bg1 from "assets/img/bg1.jpg";
import bg2 from "assets/img/bg2.jpg";
import bg3 from "assets/img/bg3.jpg";
import bg4 from "assets/img/bg4.jpg";
import bg5 from "assets/img/bg5.jpg";
import bg6 from "assets/img/bg6.jpg";
import bg7 from "assets/img/bg7.jpg";
import bg8 from "assets/img/bg8.jpg";
import bg9 from "assets/img/bg9.jpg";
import bg10 from "assets/img/bg10.jpg";
import bg11 from "assets/img/bg11.jpg";
import bg12 from "assets/img/bg12.jpg";
import bg13 from "assets/img/bg13.jpg";
import bg14 from "assets/img/bg14.jpg";
import bg15 from "assets/img/bg15.jpg";
import bg16 from "assets/img/bg16.jpg";
import bg17 from "assets/img/bg17.jpg";

export default [
    bg1,
    bg2,
    bg3,
    bg4,
    bg5,
    bg6,
    bg7,
    bg8,
    bg9,
    bg10,
    bg11,
    bg12,
    bg13,
    bg14,
    bg15,
    bg16,
    bg17,
];